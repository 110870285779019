import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./App.css";
import { toast } from "sonner";

// const socket = io("http://localhost:5001");
const socket = io("https://wa.api.luminousdemo.com");

function App() {
  const [clientId, setClientId] = useState("");
  const [qr, setQr] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [attendies, setAttendies] = useState([]);

  useEffect(() => {
    socket.on("qr", (qrCode) => {
      setQr(qrCode);
    });

    socket.on("authenticated", (session) => {
      console.log("Authenticated:", session);
    });

    socket.on("ready", () => {
      console.log("Client is ready!");
      setShowChat(true);
      toast.success("Now bot is ready to chat", {
        duration: 2000,
      });
    });

    socket.on("message", (message) => {
      console.log("🚀 ~ socket.on ~ message:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off("qr");
      socket.off("authenticated");
      socket.off("ready");
      socket.off("message");
    };
  }, []);

  const initializeClient = () => {
    if (clientId.trim()) {
      socket.emit("initializeClient", clientId);
      setIsInitialized(true);
    }
  };

  const sendMessage = () => {
    if (attendies?.length && newMessage) {
      console.log("object", attendies);
      socket.emit("sendMessage", { text: newMessage, phoneNumbers: attendies });
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setNewMessage("");
    } else {
      toast.error("Please add attendee number and valid message", {
        duration: 2000,
      });
    }
  };

  //
  const formatPhoneNumber = (number) => {
    // Remove any leading '88' if present
    if (!number.startsWith("01")) {
      return false;
    }
    // Ensure the number is exactly 11 digits
    if (number.length === 11 && /^\d+$/.test(number)) {
      return number;
    } else {
      console.log(`Invalid phone number: ${number}`);
      return false;
    }
  };

  //

  const handleSetPhoneNumber = () => {
    const wrongNumber = formatPhoneNumber(phoneNumber);
    if (!wrongNumber) {
      toast.error("Please enter a valid phone number", {
        duration: 2000,
      });
      return;
    }
    if (!phoneNumber) {
      toast.error("Please enter a valid phone number", {
        duration: 2000,
      });
    } else if (attendies?.includes(phoneNumber)) {
      toast.error("Attendee already added", {
        duration: 2000,
      });
    } else {
      setAttendies([...attendies, phoneNumber]);
      setPhoneNumber("");
    }
    // else if (attendies?.includes(phoneNumber)) {
    //   toast.error("Attendee already added", {
    //     duration: 2000,
    //   });
    // }
  };
  // 01824630876

  // 01849529136

  // 01688126772

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
      <div className="flex items-center justify-center h-screen bg-gray-100 w-4/6">
        <div className="w-full max-w-md p-6 border border-gray-300 rounded-lg bg-white shadow-md mb-5 text-center">
          <h1 className="text-2xl font-bold mb-4">WhatsApp QR</h1>
          <div className="flex justify-center mb-5">
            <input
              type="text"
              placeholder="Enter Client ID"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              className="p-2 text-md border border-gray-300 rounded-lg w-3/4"
            />
            <button
              onClick={() => {
                if (clientId) {
                  initializeClient();
                } else {
                  toast.error("Please enter a valid client ID", {
                    duration: 2000,
                  });
                }
              }}
              className="ml-2 p-2 text-md border-none rounded-lg bg-blue-500 text-white hover:bg-blue-700"
            >
              Initialize
            </button>
          </div>
          {isInitialized && (
            <div className="flex justify-center">
              {qr ? (
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                    qr
                  )}&size=200x200`}
                  alt="QR Code"
                  className="max-w-full h-auto border border-gray-300 rounded-lg"
                />
              ) : (
                <p>Waiting for QR code...</p>
              )}
            </div>
          )}
        </div>
        <>
          {showChat && (
            <div className="w-[1800px] p-3 mx-6 border border-gray-300 rounded-lg bg-white shadow-md text-center">
              <h2 className="text-xl font-bold mb-4">Chat</h2>
              <div className="flex items-center justify-center mb-4">
                <input
                  type="text"
                  placeholder="eg:017xxxxxxxxxxx"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="p-2 text-md border border-gray-300 rounded-lg w-full"
                />
                <button
                  onClick={() => {
                    handleSetPhoneNumber();
                  }}
                  className="w-12p-2 text-md border-none rounded-lg bg-blue-500 text-white hover:bg-blue-700"
                >
                  Add
                </button>
              </div>
              <div className="max-h-72 overflow-y-auto mb-4">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`p-2 mb-2 rounded-lg text-left ${
                      msg.sender === "bot"
                        ? "bg-blue-100 text-blue-900"
                        : "bg-gray-200"
                    }`}
                  >
                    {/* {msg.text} */}
                    {msg}
                  </div>
                ))}
              </div>
              <div className="flex">
                <input
                  type="text"
                  placeholder="Type a message"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="p-2 text-md border border-gray-300 rounded-lg flex-grow mr-2"
                />
                <button
                  onClick={sendMessage}
                  className="p-2 text-md border-none rounded-lg bg-blue-500 text-white hover:bg-blue-700"
                >
                  Send All
                </button>
              </div>
            </div>
          )}
        </>
        <>
          {attendies?.length ? (
            <div className="w-full p-3 mx-6 border border-gray-300 rounded-lg bg-white shadow-md text-center">
              <h2 className="text-xl font-bold mb-4">Champaign attendees</h2>
              {attendies?.map((attendie, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 p-2 mb-2 rounded-lg text-left"
                >
                  <p> {attendie}</p>
                  <div
                    className="text-md size-3 flex items-center justify-center border-none rounded-lg bg-red-500 text-white hover:bg-red-700"
                    onClick={() => {
                      setAttendies(
                        attendies.filter((item) => item !== attendie)
                      );
                    }}
                  >
                    <p>x</p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </>
      </div>
    </div>
  );
}

export default App;
